import React from 'react'
import {log} from '@vanguard/logger'

import * as filestack from 'filestack-js'
import TagManager from 'react-gtm-module'
import './photoSelectorOverrideStyle.css'

import {v1 as uuid} from 'uuid'
import {FILESTACK_UP_API_KEY} from '../../constants'
import {useStatusToast} from '../../app/ToastManager'
import {Album} from '../../types'
import {useProcessAlbumPhotosService} from '../../services/useProcessAlbumPhotosService'
import {simplePluralizeWord} from '../../utils'
import {usePreventTabClose} from '../../hooks/usePreventTabClose'
import {useRequiredAuth} from '../../stores/AuthStore'
import {albums as albumsApi} from '../../services/api/albums'
import {useAlbumViewDispatch} from '../../views/AlbumView/AlbumViewManager'

import {
  useAlbumPhotosDispatch,
  useAlbumPhotosState,
} from '../../views/AlbumView/AlbumPhotosManager'
import {usePreventTabNavigation} from '../../hooks/usePreventTabNavigation'

export type OnFileSelected = NonNullable<
  filestack.PickerOptions['onFileSelected']
>

export type OnFileUploadFinished = NonNullable<
  filestack.PickerOptions['onFileUploadFinished']
>
export type OnUploadDone = NonNullable<filestack.PickerOptions['onUploadDone']>
export type OnUploadStarted = NonNullable<
  filestack.PickerOptions['onUploadStarted']
>

export type OnCancel = NonNullable<filestack.PickerOptions['onCancel']>

export type OnFileUploadFailed = NonNullable<
  filestack.PickerOptions['onFileUploadFailed']
>

export type OnFileUploadCancel = NonNullable<
  filestack.PickerOptions['onFileUploadCancel']
>

export type DisplayMode = NonNullable<filestack.PickerOptions['displayMode']>

export type ModalSize = NonNullable<filestack.PickerOptions['modalSize']>

export type OnClose = NonNullable<filestack.PickerOptions['onClose']>

const FS_CONCURRENCY_DEFAULT = 4
const FS_FILE_RETRY = 3
const FS_FILE_RETRY_TIMEOUT = 1000 * 60 * 5 // 5 minutes
const FS_FILE_INTELLIGENT_CHUCK_SIZE = 1 * 1024 * 1024 // 1 MB
const FS_MAX_FILES_COUNT = 2000
const FS_MAX_FILE_SIZE = 1024 * 1024 * 20 // 20971520 === 20MB
const STORETO_S3_SUBFOLDER = 'upload/'

export const transformPhotoNameForProcessing = (origName: string): string => {
  const subfolderRegex = new RegExp(`^${STORETO_S3_SUBFOLDER}`, 'g')

  return origName.replace(subfolderRegex, '')
}

const fileStackClient = filestack.init(FILESTACK_UP_API_KEY, {
  sessionCache: true,
})

const defaultPickerOptions: filestack.PickerOptions = {
  storeTo: {
    location: 's3',
    path: `/${STORETO_S3_SUBFOLDER}`,
  },
  displayMode: filestack.PickerDisplayMode.overlay,
  fromSources: [
    'local_file_system',
    'googledrive',
    'googlephotos',
    'instagram',
    'facebook',
    'dropbox',
  ],
  accept: ['image/jpeg', 'image/png', 'image/heic', 'image/heif'],
  concurrency: FS_CONCURRENCY_DEFAULT,
  uploadConfig: {
    retry: FS_FILE_RETRY,
    timeout: FS_FILE_RETRY_TIMEOUT,
    intelligent: true,
    intelligentChunkSize: FS_FILE_INTELLIGENT_CHUCK_SIZE,
  },
  customText: {
    'Select Files to Upload': 'Select Photos to Upload',
    'or Drag and Drop, Copy and Paste Files':
      'Drag and drop or browse to select photos. Images must be JPG or PNG file formats and less than 20mb.',
    'Selected Files': 'Selected Photos',
    Images: 'Photos',
    'Deselect All': 'Remove All',
    'Upload more': 'Add Photos',
    Filter: 'Search',
  },
  allowManualRetry: true, // Prevent modal close on upload failure and allow users to retry.
  disableTransformer: true,
  maxFiles: FS_MAX_FILES_COUNT,
  maxSize: FS_MAX_FILE_SIZE,
  uploadInBackground: false,
}

interface Props {
  albumID: Album['ID']
  container: string
  displayMode?: DisplayMode
  openPickerByDefault?: boolean
  modalSize?: ModalSize
  onDone?: () => void
}

export const PhotoSelector: React.FC<React.PropsWithChildren<Props>> = ({
  albumID,
  container,
  displayMode = filestack.PickerDisplayMode.overlay,
  openPickerByDefault = false,
  modalSize,
  onDone,
}) => {
  const {toast} = useStatusToast()
  const {processPhoto} = useProcessAlbumPhotosService({albumID})
  const {client} = useRequiredAuth()
  const albumViewDispatch = useAlbumViewDispatch()
  const {resetPhotos, status} = useAlbumPhotosState()
  const albumPhotosDispatch = useAlbumPhotosDispatch()

  // const [albumUploadSessionID, setAlbumUploadSessionID] = React.useState(uuid())
  const albumUploadSessionID = React.useRef<string>()
  const pickerInstance = React.useRef<filestack.PickerInstance>()
  const canceledFiles = React.useRef<filestack.PickerFileMetadata[]>([])
  const notFoundFilesCount = React.useRef(0)

  const isOpen = openPickerByDefault
  usePreventTabClose(isOpen)
  usePreventTabNavigation(status === 'uploading')

  const handleUploadStarted = React.useCallback<OnUploadStarted>(
    (files) => {
      log.breadcrumb('FS Upload Started', files)
      log.breadcrumb('Creating AlbumUploadSession', {
        albumID,
      })

      const sendAlbumUploadSession = async (): Promise<void> => {
        const sessionID = uuid()

        const response = await albumsApi.createAlbumUploadSession({
          ID: sessionID,
          albumID,
          clientID: client.ID,
          photoFileNames: files.map((uploadedFile) => uploadedFile.filename),
        })

        if (response.result === 'failure') {
          toast.error(
            'Automatic album sorting failed. Go to Manage Album > Sort Photo Order after the upload completes to select sorting method.',
          )

          log.error(new Error(JSON.stringify(response.error)))
        } else {
          albumUploadSessionID.current = sessionID
        }
      }

      sendAlbumUploadSession()

      albumPhotosDispatch({type: 'START_UPLOADING'})
    },
    [albumID, client, albumPhotosDispatch, toast],
  )

  const handleOnFileUploadFinished = React.useCallback<OnFileUploadFinished>(
    async (file) => {
      let fileKey = file.key

      if (fileKey) {
        // The image processor doesn't transform HEIC/HEIF files to JPG so we will do in the client
        if (file.mimetype === 'image/heic' || file.mimetype === 'image/heif') {
          // Grab the Filestack handle of the uploaded file which is like its unique ID for filestack's system
          const {handle} = file

          //  Create a transformation URL that converts the file to JPG:
          //  output=format:jpg is the key transformation
          const transformedUrl = `https://cdn.filestackcontent.com/output=format:jpg/${handle}`

          try {
            // Store the transformed file directly to S3 via Filestack:
            const storeOptions = {
              filename: `${file.filename}_converted.jpg`,
              path: defaultPickerOptions.storeTo?.path,
            }
            const storeResponse = (await fileStackClient.storeURL(
              transformedUrl,
              storeOptions,
            )) as {key: string} // Filestack return type is just a broad Object

            if (!storeResponse.key) {
              throw new Error(
                `Filestack did not return a key for ${JSON.stringify(
                  storeResponse,
                )}`,
              )
            }

            fileKey = storeResponse.key

            TagManager.dataLayer({
              dataLayer: {
                event: 'image_conversion',
                conversion_type: file.mimetype,
              },
            })
          } catch (err) {
            if (err instanceof Error) {
              log.error(err)
            } else {
              log.error(new Error(JSON.stringify(err)))
            }
          }
        }

        // Let the image processor know that the file is ready to be processed and where it is located
        processPhoto({
          fileName: transformPhotoNameForProcessing(fileKey),
          uploadSessionID: albumUploadSessionID.current, // sessionID might be undefined still because the create request is either still in flight or errorer. This is preferred to telling a lie about the sessionID number. We have plans to prevent making the photoProcess request until the sessionID is known but do not want to change the photo process flow too drastically during summer.
        })
      }
    },
    [processPhoto],
  )

  const handleOnFileUploadFailed = React.useCallback<OnFileUploadFailed>(
    async (file, error) => {
      // File was canceled by user. Don't Log as error
      if (canceledFiles.current.find((curr) => curr.filename)) {
        return
      }

      if (!(error instanceof Error)) {
        // Assuming this is because the file was not found and it was already logged in upload.error
        if (notFoundFilesCount.current > 0) {
          notFoundFilesCount.current -= 1
          return
        }

        log.error(new Error('File Upload Failed'), (event) => {
          event.addMetadata('photo details', file)
          event.addMetadata('original error instance', error)
        })
      } else {
        log.error(error, (event) => {
          event.addMetadata('photo details', file)
        })
      }
    },
    [],
  )

  const handleOnUploadDone = React.useCallback<OnUploadDone>(
    (res) => {
      log.breadcrumb('FS Upload Done')
      resetPhotos()

      canceledFiles.current = []

      if (res.filesUploaded.length > 0) {
        toast.success(
          `${simplePluralizeWord('photo', res.filesUploaded.length)} uploaded.`,
        )
      }

      if (res.filesFailed.length > 0) {
        toast.error(
          `${simplePluralizeWord(
            'photo',
            res.filesFailed.length,
          )} failed to upload`,
        )
      }

      if (onDone) {
        onDone()
      }
    },
    [resetPhotos, onDone, toast],
  )

  const handleOnCancel = React.useCallback<OnCancel>(() => {
    log.breadcrumb('User Cancelled Upload')

    canceledFiles.current = []
  }, [])

  const handleOnFileSelected = React.useCallback<OnFileSelected>((file) => {
    const fileNameValidation = /^[A-Za-z0-9-.()_\s]+$/

    if (!fileNameValidation.test(file.filename)) {
      throw new Error(`File name ${file.filename} contains invalid characters.`)
    }

    if (file.size && file.size < 1_000) {
      throw new Error(`File name ${file.filename} is too small.`)
    }
  }, [])

  const handleOnFileUploadCancel = React.useCallback<OnFileUploadCancel>(
    (file) => {
      log.breadcrumb('canceled file', {filename: file.filename})

      // File did not get uploaded to filestack
      if (!file.url) {
        // onFileUploadCancel(file)
        canceledFiles.current.push(file)
      }
    },
    [],
  )

  const handleOnClose = React.useCallback<OnClose>(() => {
    albumViewDispatch({type: 'SET_VIEW_MODE', payload: 'initial'})
  }, [albumViewDispatch])

  const modalSizeProp = React.useMemo(
    () => (modalSize ? {modalSize} : {}),
    [modalSize],
  )

  const pickerOptions: filestack.PickerOptions = React.useMemo(
    () => ({
      ...defaultPickerOptions,
      container,
      onUploadDone: handleOnUploadDone,
      onFileUploadFailed: handleOnFileUploadFailed,
      onFileUploadFinished: handleOnFileUploadFinished,
      onFileSelected: handleOnFileSelected,
      onUploadStarted: handleUploadStarted,
      onFileUploadCancel: handleOnFileUploadCancel,
      onClose: handleOnClose,
      onCancel: handleOnCancel,
      displayMode,
      ...modalSizeProp,
    }),
    [
      handleOnCancel,
      handleOnFileUploadFailed,
      handleOnFileUploadFinished,
      handleOnFileSelected,
      handleOnUploadDone,
      handleUploadStarted,
      handleOnFileUploadCancel,
      handleOnClose,
      container,
      displayMode,
      modalSizeProp,
    ],
  )

  // Subscribe/Unsubscribe to different FS error listener
  React.useEffect(() => {
    fileStackClient.on('upload.error', (filestackError) => {
      // Do not log error when it was a user canceled file
      if (
        filestackError?.message === 'FS-Cannot upload file part' &&
        canceledFiles.current.length > 0
      ) {
        return
      }

      // Filestack gave a real error to log
      if (
        filestackError?.currentTarget?.error &&
        filestackError.currentTarget.error instanceof Error
      ) {
        const {
          currentTarget: {error},
        } = filestackError
        // file was moved before upload, so only info log and supress fileFail error log
        if (error.name === 'NotFoundError') {
          log.info(error)
          notFoundFilesCount.current += 1
        } else {
          log.error(error)
        }
        return
      }

      // No idea what this error is
      log.error(new Error('FileStack upload.error'), (event) => {
        event.addMetadata('filestackError', filestackError)
      })
    })

    return (): void => {
      fileStackClient.off('upload.error')
    }
  }, [])

  // Get PickerJS from FileStack
  React.useEffect(() => {
    try {
      if (!pickerInstance.current) {
        pickerInstance.current = fileStackClient.picker(pickerOptions)
      }
    } catch (e) {
      if (e instanceof Error) {
        log.error(e)
      }
    }
  }, [pickerOptions])

  // Toggle Open/Close of uploader modal
  React.useEffect(() => {
    if (isOpen && pickerInstance.current) {
      try {
        pickerInstance.current.open()
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        }
      }
    }

    return (): void => {
      //   if (isOpen && pickerInstance.current) {
      try {
        if (pickerInstance.current) {
          pickerInstance.current.cancel()
          pickerInstance.current.close()
          // pickerInstance.current = undefined // TODO: Not sure what changed, but was unable to open/close modal mutliple times due to this
        }
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        }
      }
    }
  }, [isOpen])

  return null
}
